import { template as template_c6132d81627749d98a7bd5f6e0c1ce01 } from "@ember/template-compiler";
const FKControlMenuContainer = template_c6132d81627749d98a7bd5f6e0c1ce01(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
