import { template as template_84513d9f23b747f7a2d36b210a6ed39b } from "@ember/template-compiler";
const SidebarSectionMessage = template_84513d9f23b747f7a2d36b210a6ed39b(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
