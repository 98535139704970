import { template as template_909b211277c04bb0a27b9a045237085a } from "@ember/template-compiler";
const FKLabel = template_909b211277c04bb0a27b9a045237085a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
